import React from "react";
import { Paper, Container, Grid, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { styled } from "@mui/material/styles";

interface HeroProps {
    header: string,
    description:string,
    header2?: string,
    image?: IGatsbyImageData
}

const StyledPaper = styled(Paper)({
    minHeight: "60vh",
    //backgroundImage: "url(https://www.freepnglogos.com/uploads/under-construction-png/under-construction-purchase-supplements-renew-total-body-wellness-26.png)",
    backgroundColor: "#ececec",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius:0
}) as typeof Paper;

const Hero = (props:HeroProps) => {

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <StyledPaper elevation={0}>
            <Container sx={{width: "100%", height: "100%"}}>
                <Box pt={isLargeScreen ? 10 : 5}>
                    <Grid sx={{width: "100%", height: "100%"}} container justifyContent="space-between" alignItems="center">
                        <Grid item sm={12} md={8}>
                            <Box>
                                <Typography variant="h2" component="h1" fontSize={isLargeScreen ? theme.typography.h2.fontSize : "2.8em"}>{props.header}</Typography>
                                <Typography variant="h6" fontSize={isLargeScreen ? theme.typography.h6.fontSize : "1.1em"}>{props.description}</Typography>
                                <Box py={5}>
                                    <Typography variant="h4" color="primary" fontSize={isLargeScreen ? theme.typography.h4.fontSize : "1.4em"}>
                                        {props.header2}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={4} alignItems="center">
                            {   props.image != undefined &&
                                <GatsbyImage image={props.image} alt="Coming soon image"  />
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </StyledPaper>
    )
}

export default Hero;