import React from "react";
import { graphql } from "gatsby";

import Layout from '../../layout'
import Hero from '../../components/hero';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface PageProperties {
    data: {
      page: {
        url: string,
        title: string,
        content:[{
            internal: {
                type: string
            },
            header: string,
            description: string,
            header2: string,
            image: {
                gatsbyImageData: IGatsbyImageData
            }
        }]
      }
    }
}
  
const BasicPageTemplate = ({data: { page }}: PageProperties) => {

    return (
        <Layout>
          <Hero
            header={page.content[0].header}
            description={page.content[0].description}
            header2={page.content[0].header2}
            image={page.content[0].image.gatsbyImageData} />
        </Layout>
      )

}

export const query = graphql`
    query($id: String!) {
        page: contentfulPage(id: { eq: $id }) {
            url
            title
            content {
                ... on ContentfulPageSectionHero {
                    header
                    description
                    header2
                    image {
                        gatsbyImageData
                    }
                }
            }
        }
    }
  `;

export default BasicPageTemplate